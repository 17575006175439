import Apis from '@/utils/api/Apis';
import ApiProvider from '@/utils/api/Apis';
import { Client } from '@/utils/api/SchedulerApiClient';
import axios from 'axios';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({ name: 'user', stateFactory: true, namespaced: true })
export default class UserStore extends VuexModule {
     
    public token: string | null = (() => {
        try {
            return sessionStorage.getItem('token');
        } catch {
            return '';
        }
    })();

     @Mutation
     async setToken(token: string) {
         if(token) {
            try {
                sessionStorage.setItem('token', token);
            } catch(e) {
                // You may not have access to session storage in case it is in an iframe
            }
             this.token = token;
         }
     }
   // action 'incr' commits mutation 'increment' when done with return value as payload
     @Action({ commit: 'setToken', rawError: true })
     async ensureLogin(force: boolean) {
         let token = this.token;
         if(!token || force) {
             console.log('Authenticating');
             if(!location.search.startsWith('?jwt=')) {
                 window.location.href = 'https://consultant-login.norriq.dev/consultant-login?destination=' + window.location.origin + window.location.pathname;
             } else {
                 const jwt = location.search.substring('?jwt='.length);
                 window.history.replaceState({}, '', window.location.pathname);
                 token = await (await axios.post(`${Apis.schedulerBaseUrl}/scheduler/AdAuthenticate/GetLocalToken?jwt=${jwt}`)).data;
             }
         }
         if(token) {
            Apis.setSchedulerToken(token);
            this.setToken(token);
         }
         return token;
    }
}