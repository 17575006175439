






































import { Component, Mixins } from 'vue-property-decorator';
import StoreMixin from '@/mixins/StoreMixin';
import Apis from './utils/api/Apis';
import EventApi from './utils/api/EventApi';

@Component({})
export default class App extends Mixins(StoreMixin) {
  public test: string = "";
  async created() {
    await this.$userStore.ensureLogin(false);
    var eventApi = new EventApi(Apis.websocketBaseUrl);
    eventApi.start();
    }
}
