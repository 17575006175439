import axios from 'axios';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import Vue from 'vue';
import { GetHistoryBySearchRequest, IRunningJob, IJobDescription, ISearchQuery } from '@/utils/api/SchedulerApiClient';
import Apis from '@/utils/api/Apis';

@Module({ name: 'runningJobs', stateFactory: true, namespaced: true })
export default class RunningJobs extends VuexModule {

    public eaterJobs: {[eaterId: string]: any } = {};
    
    // public loadedJobs: {[runningJobId: string]: IRunningJob } = {};

    // public newestCreated: Date | null = null;

    @Mutation
    public setEaterJobs(args: {key: string, data: any}) {
        Vue.set(this.eaterJobs, args.key, args.data);
    }

    @Action
    public async refresh() {
        const result = await Apis.schedulerAxios.get('/scheduler/JobRunner/JobRunnerStates')
        const dataResult = JSON.parse(result.data);
        for(let key in dataResult) {
            this.setEaterJobs({key: key, data: dataResult[key]})
        }
    }

}
