import store from '@/store/index';

export default class EventApi  {

    private socket: WebSocket;
    private isConnected: boolean = false;
    private uri: string;
    private isStarted: boolean = false;
    private pingTimer: number;

    /**
     *
     */
    constructor(uri: string) {
        this.uri = uri;
    }

    public start(): void {
        this.isStarted = true;
        this.build();
        // console.log('state', store.state);
    }

    public stop(): void {
        this.isStarted = false;
        if (this.socket) {
          this.socket.close();
        }
    }

    private onOpen() {
      this.isConnected = true;
    //   this.sendSubscribeToMessage();
    //   this.pingTimer = setInterval(() => {
    //     this.ping();
    //   }, 5000); // Ping ever 5 sec
    }

    private onMessage(message: MessageEvent) {
      const incomming = JSON.parse(message.data);
      const type: string = incomming._type;
      if(type.endsWith('RunningJob')) {
        store.dispatch('history/setRunningJob', {job: incomming, latest: true});
      }

      if(type.endsWith('GetQueuesReportResponse')) {
        store.commit('queue/loadQueuesCommit', incomming);
      }


    }
    private onError(err: Event) {
        this.isConnected = false;
    }

    private onClose(e: CloseEvent) {
      this.isConnected = false;
      clearInterval(this.pingTimer);
      if (this.isStarted) {
          setTimeout(this.build.bind(this), 1000);
      }
    }

   
    private ping() {
        // console.log('Sending ping');
        this.socket.send('ping');
    }

    private build(): void {
        // const userState = (store.state as any).user as UserState;
        const uri = this.uri; //.replace('{token}', userState.jwtToken || '');
        console.log(`Connecting to ${uri}`);
        this.socket = new WebSocket(uri);
        this.socket.onopen = this.onOpen.bind(this);
        this.socket.onmessage = this.onMessage.bind(this);
        this.socket.onclose = this.onClose.bind(this);
        this.socket.onerror = this.onError.bind(this);
    }
}


