














import { IRunningJob, ScheduledJob } from "@/utils/api/SchedulerApiClient";
import { Vue, Component, Prop } from "vue-property-decorator";



@Component
export default class GetRunningJobIconComponent extends Vue {
    @Prop({required: true})
    public job: IRunningJob | undefined;

    @Prop({required: false})
    public scheduled: boolean;

}
