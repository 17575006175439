import moment from 'moment';
import { IRunningJob, ScheduledJob, IJobDescription, QueueDescription } from "@/utils/api/SchedulerApiClient";
import StoreMixin from "./StoreMixin";
import { Component, Mixins } from 'vue-property-decorator';

export interface IModeDetails
{
    mode: string;
    lastJob: (IRunningJob | undefined);
    schedules: ScheduledJob[];
}

export interface IQueueDetails
{
    queue: QueueDescription;
    lastJob: (IRunningJob | undefined);
    inQueue: number;
}

@Component
export default class JobMixin extends Mixins(StoreMixin) {
    public getCreated(lastJob?: IRunningJob): any {
        if(!lastJob?.created) return;
        return moment(lastJob?.created).calendar();
    }

    public getNextDate(details: IModeDetails): any
    {
        if(details.schedules.length == 0) return '';
        const schedule = details.schedules[0];
        if(schedule.repeatsEvery && details.lastJob?.created) {
            let lastCreated = moment(details.lastJob.created);
            let startAgain = lastCreated.add(schedule.repeatsEvery);
            if(!schedule.endsAt || moment().startOf('day').add(schedule.endsAt).isAfter(startAgain))
                return startAgain.calendar();

        }
        if(schedule.startsAt) {
            return moment().startOf('day').add(1, 'days').add(schedule.startsAt).calendar();
        }

        // if(schedule?.repeatsEvery)
        // this.$moment
    }

    public getQueues(job: IJobDescription): IQueueDetails[] {
        const result: IQueueDetails[] = [];
        if(!job.queues) return result;

        const jobsByMode = this.$historyStore.lastRunningJobs[job.job.id] || {};

        for(let queue of job.queues) {
            result.push(<IQueueDetails>{
                queue: queue,
                inQueue: this.$queueStore.queueCount[queue.detailsKey || ''] || 0,
                lastJob: jobsByMode[queue.detailsKey || '']
            });
        }
        return result;
    }


    public getModes(job: IJobDescription): IModeDetails[] {
        const result: IModeDetails[] = [];

        const jobsByMode = this.$historyStore.lastRunningJobs[job.job.id] || {};
        const modes = job.executionModes || [];
        if(modes.length == 0) modes.push('');

        for(let mode of modes) {
            result.push(<IModeDetails>{
                mode: mode || '',
                lastJob: jobsByMode[mode || ''],
                schedules: job.schedules?.filter(s => (s.mode || '') == (mode || '')) || []
            })
        }
        
        // for(let mode in jobsByMode) {
        //     const existingMode = modes.find(m => (m ?? '') == (mode ?? ''));
        //     if(typeof existingMode === 'undefined') {
        //         result.push({
        //             mode: mode,
        //             lastJob: jobsByMode[mode],
        //             schedules: job.schedules?.filter(s => (s.mode || '') == (mode || '')) || []
        //         });

        //     }
        // }
        return result;
    }
}