




























import StoreMixin from "@/mixins/StoreMixin";
import NorriqPageRenderer from "@/components/layout/PageRenderer.vue";
import { IRunningJob, SearchFacetType, SortByDirection } from "@/utils/api/SchedulerApiClient";
import JobMixin, { IModeDetails } from "@/mixins/JobMixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { EnumFormat, IColumn } from "@/utils/helpers/searching";
import NorriqTableSearch from "@/components/core/search/TableSearch.vue";
import NorriqRunningJobIcon from "@/components/history/RunningJobIcon.vue";
import { ISearchFacet } from "@/store/search";
import moment from "moment";
@Component({
  components: {
    NorriqPageRenderer,
    NorriqTableSearch,
    NorriqRunningJobIcon,
  },
})
export default class History extends Mixins(StoreMixin, JobMixin) {
  @Prop({ required: false, default: () => {} })
  public searchQuery: any;

  @Prop({ required: false, default: "" })
  public searchRef: string;

  public get newestJobsRef(): { [key: string]: IRunningJob } {
    return this.$historyStore.loadedJobs;
  }

  public get newestCreated(): Date | null {
    return this.$historyStore.newestCreated;
  }
  public newestCreatedPreviousValue: Date | null = null;

  public getMode(job: IRunningJob) {
    if(job.jobDetailsKey) {
      var split = job.jobDetailsKey.split('.');
      if(split.length > 1) {
        return split[split.length-2];
      }
    }
    return job.jobDetailsKey || 'default';
  }

  public get columns(): IColumn[] {
    return [
      {
        name: "Name",
        field: "item.configuration.name",
        class: "cell-responsive",
        format: EnumFormat.default,
      },
      {
        name: "Mode",
        field: "item.jobDetailsKey",
        class: "cell-responsive mode",
        format: EnumFormat.default,
      },
      {
        name: "Started",
        field: "item.created",
        class: "cell-responsive",
        format: EnumFormat.since,
        sorting: {
          sortDirection: SortByDirection.Descending,
          default: true,
          field: "created",
        },
      },
      {
        name: "Duration",
        field: "item.duration",
        class: "cell-responsive",
        sorting: {
          sortDirection: SortByDirection.Descending,
          default: false,
          field: "durationLong",
        },
      },
      {
        name: "State",
        field: "item.state",
        class: "cell-responsive",
        format: EnumFormat.default,
        sorting: {
          sortDirection: SortByDirection.Ascending,
          default: false,
          field: "state",
        },
      },
      {
        name: "Worker",
        field: "item.workerIdentity",
        class: "cell-responsive",
        format: EnumFormat.default,
        sorting: {
          sortDirection: SortByDirection.Ascending,
          default: false,
          field: "workerIdentity",
        },
      },
      {
        name: "Importance",
        field: "item.importance",
        class: "cell-responsive",
        format: EnumFormat.default,
        sorting: {
          sortDirection: SortByDirection.Ascending,
          default: false,
          field: "importance",
        },
      },
    ];
  }

  public get facets(): ISearchFacet[] {
    return [
      {
        type: SearchFacetType.CheckboxOr,
        name: "Job",
        field: "configuration.name",
        queryField: "jobs",
      },
      {
        type: SearchFacetType.CheckboxOr,
        name: "Mode",
        field: "jobDetailsKey",
        queryField: "modes",
      },
      {
        type: SearchFacetType.CheckboxOr,
        name: "State",
        field: "state",
        queryField: "state",
      },
      {
        type: SearchFacetType.CheckboxOr,
        name: "Importance",
        field: "importance",
        queryField: "importance",
      },
    ];
  }

  async mounted() {
    this.newestCreatedPreviousValue = this.newestCreated;
    await this.$jobStore.loadJobs();
  }

  @Watch("newestCreated")
  async onNewestCreatedChange() {
    // console.log('Update Check', {newestCreated: (this.newestCreated?.getTime() || 0), newestCreatedPreviousValue: (this.newestCreatedPreviousValue?.getTime() || 0)});
    if ((this.newestCreated?.getTime() || 0) != (this.newestCreatedPreviousValue?.getTime() || 0)) {
      // console.log('Update now!');
      await this.$searchStore.search({ ref: "history", force: true });
      this.newestCreatedPreviousValue = this.newestCreated;
    }
  }
}
