import { ISearchContainer, ISearchFacetSelectedCheckbox } from "@/store/search";
import { ISearchQuery, SearchFacetType, SortByDirection } from "../api/SchedulerApiClient";
import { hashObject } from "./hashing";

export interface IColumn {
    name: string;
    field: string;
    class?: string;
    format?: EnumFormat;
    sorting?: {
        sortDirection: SortByDirection;
        default: boolean;
        field: string;
    }
}

export enum EnumFormat {
    default = 'default',
    datetime = 'datetime',
    since = 'since',
    yesNo = 'yesno'
}

export function buildSearchHash(container: ISearchContainer): number {
    const obj = {
        configuration: container.configuration,
        currentPage: container.currentPage,
        facetSelected: container.facetSelected,
        sortingSelected: container.sortingSelected,
        freeTextSearch: container.freeTextSearch
    };
    return hashObject(obj);
}

export function searchQueryBuilder(container: ISearchContainer): ISearchQuery {
    const queries: ISearchQuery[] = [];
    if((<any>container.configuration.search).type !== 'empty') {
        queries.push(container.configuration.search);
    }
    for (var facet of container.configuration.facets) {
        const equalQueries: ISearchQuery[] = [];
        if(container.facetSelected[facet.field]) {
                switch(facet.type) {
                    case SearchFacetType.CheckboxAnd:
                    case SearchFacetType.CheckboxOr:
                        const facetSelectedGroup = <ISearchFacetSelectedCheckbox | undefined>container.facetSelected[facet.field];
                        if(facetSelectedGroup) {
                            for(let key in facetSelectedGroup.selected) {
                                if(facetSelectedGroup.selected[key]) {
                                    equalQueries.push({
                                        "type": "equals",
                                        "field": facet.field,
                                        "value": key
                                    });
                                }
                            }
                        }
                        break;
                }
        }
        if(equalQueries.length > 0) {
            queries.push({
                "type": facet.type == SearchFacetType.CheckboxAnd ? "and" : "or",
                "queries": equalQueries
            });
        }
    }

    let queriesCombined: any = {
        "type": "and",
        "queries": queries,
        "weighted": false
    };

    if(container.freeTextSearch) {
        queriesCombined = {
            "type": "and",
            "weighted": true,
            "queries": [
                queriesCombined,
                {
                    "type": "text",
                    "value": container.freeTextSearch,
                    "fields": container.configuration.textSearchFields || []
                }
            ]
        };
    }
    return queriesCombined;
}