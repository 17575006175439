import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMoment from 'vue-moment';
import VueI18n from 'vue-i18n';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import { i18n } from '@/plugins/i18n';
import EventApi from './utils/api/EventApi';

Vue.use(VueI18n, {
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages: {

    }, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
  });



Vue.config.productionTip = false
Vue.use(VueMoment);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
