






















































































import { Vue, Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { ISearchFacet, ISearchResultItem, ISortingOption } from "@/store/search";
import { IColumn } from "@/utils/helpers/searching";
import { ISearchQuery, SortByDirection } from "@/utils/api/SchedulerApiClient";
import StoreMixin from "@/mixins/StoreMixin";
import NorriqPagination from "./Pagination.vue";
import NorriqFacetFilterSingle from "./FacetFilterSingle.vue";
import NorriqFacetFilterDropdown from "./FacetFilterDropdown.vue";

@Component({
  components: {
    NorriqPagination,
    NorriqFacetFilterSingle,
    NorriqFacetFilterDropdown,
  },
})
export default class TableSearch extends Mixins(StoreMixin) {
  @Prop({ required: false, default: "/backoffice/api/document/GetBySearch" })
  public endpoint: string;

  @Prop({ required: true })
  public columns: IColumn[];

  @Prop({ required: false })
  public detailsPage: string | undefined;

  @Prop({ required: false })
  public detailsPageId: string | undefined;

  @Prop({
    required: false,
    default: () => `searchref:${Math.random().toString()}`,
  })
  public searchRef: string;

  @Prop({ required: false, default: 30 })
  public pageSize: number;

  @Prop({ required: false, default: false })
  private loadAutoRefresh: boolean;

  @Prop({ required: false, default: () => {} })
  public searchQuery: ISearchQuery;

  @Prop({ required: false, default: () => [] })
  public facets: ISearchFacet[];

  @Prop({ required: false, default: () => [] })
  public textSearchFields: string[];

  @Prop({ required: false, default: () => {} })
  public newestItemRef: { [key: string]: any };

  public isInitialSearching: boolean = true;

  public searchText: string = "";

  private refreshInterval: number;
  public autoRefresh: boolean = false;

  public get isSearching(): boolean {
    return this.$searchStore.isSearching(this.searchRef);
  }
  public get items(): ISearchResultItem[] {
    return this.$searchStore.items(this.searchRef).map(
      (i) =>
        <ISearchResultItem>{
          score: i.score,
          item: (i.item.id ? this.newestItemRef[i.item.id] : undefined) || i.item,
        }
    );
  }

  public get selectedSortingOption(): ISortingOption | undefined {
    return this.$searchStore.selectedSortingOption(this.searchRef);
  }

  public get topFacet(): ISearchFacet | undefined {
    return this.facets.find((f) => f.name === "top");
  }

  public getFacetName(field: string): string | undefined {
    return this.facets.find((f) => `item.${f.field}` == field)?.name;
  }

  public getItemValue(item: ISearchResultItem, column: IColumn): any {
    try {
      const val = eval("item." + column.field);
      return val;
    } catch {
      return "";
    }
  }

  public isSortingAsc(column: IColumn): boolean {
    return this.selectedSortingOption && column.sorting && `${column.name}-asc` === this.selectedSortingOption.name ? true : false;
  }

  public isSortingDesc(column: IColumn): boolean {
    return this.selectedSortingOption && column.sorting && `${column.name}-desc` === this.selectedSortingOption.name ? true : false;
  }

  public async onSortClick(column: IColumn) {
    if (column.sorting) {
      const sortingName = `${column.name}-${column.sorting?.sortDirection === SortByDirection.Ascending ? "asc" : "desc"}`;
      const reversedSortingName = `${column.name}-${column.sorting?.sortDirection === SortByDirection.Ascending ? "desc" : "asc"}`;
      if (this.selectedSortingOption?.name === sortingName) {
        this.$searchStore.setSorting({
          ref: this.searchRef,
          sorting: <ISortingOption>this.sortingOptions.find((s) => s.name === reversedSortingName),
        });
      } else {
        this.$searchStore.setSorting({
          ref: this.searchRef,
          sorting: <ISortingOption>this.sortingOptions.find((s) => s.name === sortingName),
        });
      }

      await this.$searchStore.setPage({ ref: this.searchRef, currentPage: 1 });
      await this.$searchStore.search({ ref: this.searchRef });
    }
  }

  public async onItemClick(item: ISearchResultItem) {
    if (this.detailsPage && this.detailsPageId) {
      const id = eval("item." + this.detailsPageId);
      this.$router.push({ path: this.detailsPage + id });
    }
    this.$emit("click", item);
  }

  // Searching >>
  private _timeoutNumber: number;

  @Watch("searchText")
  public async onSearchTextChange() {
    clearTimeout(this._timeoutNumber);
    this._timeoutNumber = setTimeout(this.debounceSearchTextChange, 200);
  }

  private async debounceSearchTextChange() {
    console.log("Wee", this.searchText);
    await this.$searchStore.setFreeText({
      ref: this.searchRef,
      text: this.searchText,
    });
    await this.$searchStore.setPage({ ref: this.searchRef, currentPage: 1 });
    await this.$searchStore.clearFacetSelected({ ref: this.searchRef });
    await this.$searchStore.search({ ref: this.searchRef });
  }
  // Searching <<

  private get sortingOptions(): (ISortingOption & { default: boolean })[] {
    const options: (ISortingOption & { default: boolean })[] = [];
    for (let column of this.columns.filter((c) => c.sorting)) {
      if (!column.sorting) continue; // Compiler error otherwise
      const nameSplit = column.name.split(".");
      const name = nameSplit[nameSplit.length - 1];
      options.push({
        name: `${column.name}-${column.sorting?.sortDirection === SortByDirection.Ascending ? "asc" : "desc"}`,
        queryValue: `${name}-${column.sorting?.sortDirection === SortByDirection.Ascending ? "asc" : "desc"}`,
        sortings: [
          {
            field: column.sorting.field,
            direction: column.sorting.sortDirection,
          },
        ],
        default: column.sorting.default,
      });
      options.push({
        name: `${column.name}-${column.sorting?.sortDirection === SortByDirection.Ascending ? "desc" : "asc"}`,
        queryValue: `${name}-${column.sorting?.sortDirection === SortByDirection.Ascending ? "desc" : "asc"}`,
        sortings: [
          {
            field: column.sorting.field,
            direction: column.sorting.sortDirection === SortByDirection.Ascending ? SortByDirection.Descending : SortByDirection.Ascending,
          },
        ],
        default: false,
      });
    }
    // Sort so default comes first :)
    options.sort((x, y) => (x.default === y.default ? 0 : x.default ? -1 : 1));
    return options;
  }

  public async mounted() {
    await this.search();
    this.autoRefresh = this.loadAutoRefresh;
    this.refreshInterval = setInterval(async () => {
      if (this.autoRefresh) await this.search();
    }, 5000);
  }
  public async unmounted() {
    clearInterval(this.refreshInterval);
  }

  @Watch("endpoint")
  @Watch("searchQuery")
  public async search() {
    this.$searchStore.setConfiguration({
      ref: this.searchRef,
      configuration: {
        facets: this.facets,
        pageSize: this.pageSize,
        search: this.searchQuery || {},
        sortingOptions: this.sortingOptions,
        endpoint: this.endpoint,
        textSearchFields: this.textSearchFields,
      },
      //   query: this.$router.currentRoute.query,
    });
    await this.$searchStore.search({ ref: this.searchRef });
    this.isInitialSearching = false;
  }

  private getQueryName(val: string) {
    return val.toLowerCase().replace(/[^a-z0-9]/gi, "");
  }
}
