





































































































































import StoreMixin from "@/mixins/StoreMixin";
import NorriqPageRenderer from "@/components/layout/PageRenderer.vue";
import NorriqRunningJobIcon from "@/components/history/RunningJobIcon.vue";
import { IRunningJob, SearchFacetType, SortByDirection } from "@/utils/api/SchedulerApiClient";
import JobMixin, { IModeDetails } from "@/mixins/JobMixin";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { EnumFormat, IColumn } from "@/utils/helpers/searching";
import NorriqHistoryTable from "@/components/history/HistoryTable.vue";
import NorriqReportingEventsTable from "@/components/history/ReportingEventsTable.vue";
import { ISearchFacet } from "@/store/search";
import moment from "moment";
import Apis from "@/utils/api/Apis";

@Component({
  components: {
    NorriqPageRenderer,
    NorriqHistoryTable,
    NorriqRunningJobIcon,
    NorriqReportingEventsTable,
  },
})
export default class History extends Mixins(StoreMixin, JobMixin) {
  public get runningJobId() {
    return this.$route.params.id;
  }

  public duration: string = "";

  public get runningJob(): IRunningJob | undefined {
    return this.$historyStore.loadedJobs[this.runningJobId];
  }

  public get extraInfo(): {key: string, value: string}[]{
    const result: {key: string, value: string}[] = [];
    for(let key in this.runningJob?.extraInfo) {
      result.push({key, value: this.runningJob?.extraInfo[key] || ''});
    }
    return result;
  }

  public get showRunAgain(): boolean {
    var index = this.runningJob?.details?.['type']?.indexOf('MessageQueueJobDetails');
    return (index || -1) == -1;
  }

  public get columns(): IColumn[] {
    return [
      {
        name: "Name",
        field: "item.jobDescription.name",
        class: "cell-responsive",
        format: EnumFormat.default,
      },
      {
        name: "Started",
        field: "item.created",
        class: "cell-responsive",
        format: EnumFormat.since,
        sorting: {
          sortDirection: SortByDirection.Descending,
          default: true,
          field: "created",
        },
      },
      {
        name: "Mode",
        field: "item.mode",
        class: "cell-responsive",
        format: EnumFormat.default,
      },
      {
        name: "State",
        field: "item.state",
        class: "cell-responsive",
        format: EnumFormat.default,
      },
    ];
  }

  public get facets(): ISearchFacet[] {
    return [
      {
        type: SearchFacetType.CheckboxOr,
        name: "Job",
        field: "jobDescription.name",
        queryField: "jobs",
      },
      {
        type: SearchFacetType.CheckboxOr,
        name: "Mode",
        field: "mode",
        queryField: "modes",
      },
    ];
  }

  private refreshDurationInterval: number;
  public refreshDuration(): void {
    if (this.runningJob?.duration) {
      var match = /^[^.]+\.[0-9]{1,2}/.exec(this.runningJob.duration);
      if (match) {
        this.duration = match[0];
      }
    } else if (this.runningJob?.started) {
      const now = moment(new Date());
      const started = moment(this.runningJob.started);
      const duration = now.diff(started, "ms");
      const milliseconds = Math.floor((duration % 1000) / 100);
      const seconds = Math.floor((duration / 1000) % 60);
      const minutes = Math.floor((duration / (1000 * 60)) % 60);
      const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      this.duration = `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }
  }

  @Watch("runningJobId")
  private async loadJob() {
    if (this.runningJobId) {
      await this.$historyStore.loadJob({ runningJobId: this.runningJobId });
    }
    this.refreshDuration();
  }

  async mounted() {
    await this.loadJob();
    this.refreshDurationInterval = setInterval(this.refreshDuration, 1000);
  }

  async unmounted() {
    clearInterval(this.refreshDurationInterval);
  }

  async onStopJobClick() {
    const result = await Apis.schedulerApi.stopJobById({ id: this.runningJobId });
    console.log(result);
  }

  public async onStartJobClick() {
    if (this.runningJob?.configuration?.id) {
      const startJob = await Apis.schedulerApi.startJob({ jobConfigurationId: this.runningJob?.configuration?.id, mode:this.runningJob.jobDetailsKey || "" });
      this.$router.push(`/history/${startJob.queuedJobId}`);
    }
  }
}
