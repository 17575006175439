import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Jobs from '../views/Jobs.vue'
import Job from '../views/Job.vue'
import History from '../views/History.vue'
import HistoryDetails from '../views/HistoryDetails.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'jobs',
    component: Jobs
  },
  {
    path: '/job/:id',
    name: 'job',
    component: Job
  },
  {
    path: '/history/:id',
    name: 'history-details',
    component: HistoryDetails
  },
  {
    path: '/history',
    name: 'history',
    component: History
  },
  {
    path: '/pods',
    name: 'pods',
    component: () => import('../views/Pods.vue')
  }

]

const router = new VueRouter({
  routes
})

export default router
