import { Client } from './SchedulerApiClient';
import axios, { AxiosInstance } from 'axios';

class Apis {
    public schedulerApi: Client;
    public schedulerAxios: AxiosInstance;
    private token: string | null = null;
    public schedulerBaseUrl: string = '';
    public websocketBaseUrl: string = '';

    constructor() {
        if(location.port == '8080') {
            // this.websocketBaseUrl = 'wss://localhost:44398/scheduler/websocket';
            // this.schedulerBaseUrl = 'https://localhost:44398';
            this.websocketBaseUrl = 'wss://scheduler-test.tefcold.norriq.dev/scheduler/websocket';
            this.schedulerBaseUrl = 'https://scheduler-test.tefcold.norriq.dev';
        } else {
            this.websocketBaseUrl = `${(location.protocol == 'https:' ? 'wss' : 'ws')}://${location.hostname}:${location.port}/scheduler/websocket`;
            this.schedulerBaseUrl =  `${location.protocol}//${location.hostname}:${location.port}`;
        }

        this.schedulerAxios = axios.create({
            // Skips parsing it to json, so we do not get errors in our generated clients
            transformResponse: (data: any) => {
                // console.log(data);
                return data;
            },
            baseURL: this.schedulerBaseUrl
        });
        this.schedulerAxios.interceptors.request.use(req => {
            if (req == null || req.url == null || !req.headers)
                return req;
                
                if(this.token) {
                    req.headers.Authorization = 'Bearer ' + this.token;
                }   
                req.url += req.url.indexOf('?') > -1
                ? '&'
                : '?';
            req.url += '_ts=' + Math.random();

            return req;
        });
        this.schedulerApi = new Client(this.schedulerBaseUrl, this.schedulerAxios);
    }

    public setSchedulerToken(token: string) {
        this.token = token;

    }

}
export default new Apis();
