






















































import StoreMixin from "@/mixins/StoreMixin";
import NorriqPageRenderer from "@/components/layout/PageRenderer.vue";
import { IRunningJob, ISearchQuery, SearchFacetType, SortByDirection } from "@/utils/api/SchedulerApiClient";
import JobMixin, { IModeDetails } from "@/mixins/JobMixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { EnumFormat, IColumn } from "@/utils/helpers/searching";
import NorriqTableSearch from '@/components/core/search/TableSearch.vue';
import NorriqRunningJobIcon from '@/components/history/RunningJobIcon.vue';
import { ISearchFacet } from "@/store/search";
import moment from "moment";
import axios from 'axios';


@Component({
    components: {
        NorriqPageRenderer,
        NorriqTableSearch,
        NorriqRunningJobIcon
    }
})
export default class History extends Mixins(StoreMixin, JobMixin) {


    public getOffset(timestamp: string) {
        const duration = moment(timestamp).diff(this.runningJob.started, 'ms');
        const milliseconds = Math.floor((duration % 1000) / 100);
        const seconds = (duration / 1000) % 60;
        const minutes = Math.floor((duration / (1000 * 60)) % 60);
        const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        return `${hours}:${(minutes < 10 ? '0' : '')}${minutes}:${(seconds < 10 ? '0' : '')}${seconds.toFixed(2)}`;
    }

    @Prop({ required: true })
    public runningJob: IRunningJob;

    @Prop({ required: false, default: 'logentry' })
    public searchRef: string;

    public get searchQuery(): ISearchQuery {
        return {};
    }

    public get columns(): IColumn[] {
        return [
            {
                name: "Timestamp",
                field: "item.timestamp",
                format: EnumFormat.datetime,
                sorting: {
                    sortDirection: SortByDirection.Descending,
                    default: true,
                    field: 'timestamp'
                },
                class: 'timestamp'
            },
            {
                name: "Category",
                field: "item.category",
                class: "category"
            },
            {
                name: "LogLevel",
                field: "item.logLevel",
                class: "loglevel"
            },
            {
                name: "Message",
                field: "item.message"
            }];

    }

    public get facets(): ISearchFacet[] {
        return [<ISearchFacet>{
            type: SearchFacetType.CheckboxOr,
            name: 'logLevel',
            field: 'logLevel',
        },
        <ISearchFacet>{
            type: SearchFacetType.CheckboxOr,
            name: 'category',
            field: 'category',
        },
        ]
    }

    public getLastCategory(category: string) {
        const split = category.lastIndexOf('.');
        return category.substring(split + 1);

    }


    private refreshInterval: number;
    private isPending = false;
    private refreshSource = axios.CancelToken.source();
    private refreshTimeout: number;

    // TODO: Rewrite to sockets
    public async mounted() {
        clearInterval(this.refreshInterval);
        this.refreshInterval = setInterval(this.refresh, 2000);
    }

    @Watch('runningJob')
    public async refresh() {
        if (!this.$searchStore.containers[this.searchRef]) {
            return;
        }
        if (this.isPending && (!this.refreshTimeout || this.refreshTimeout <= 0)) {
            this.refreshTimeout = setTimeout(() => {
                this.refreshSource.cancel();
                this.isPending = false;
            }, 15000)
        }
        else if (!this.isPending) {
            try {
                this.isPending = true;
                clearTimeout(this.refreshTimeout);
                this.refreshSource = axios.CancelToken.source();
                await this.$searchStore.search({ ref: this.searchRef, force: true, ct: this.refreshSource });
                this.isPending = false;
            }
            catch (error) {
                //For some reason axios cancel, does throw an excecption. Check if it is just due to cancelation if it is => ignore it.
                if (axios.isCancel(error)) {
                    console.debug("nothing wrong here", error)
                }
                else {
                    throw error;
                }
            }
            finally {
                clearTimeout(this.refreshTimeout);
                this.refreshTimeout = -1;

            }
        }
    }
    public async unmounted() {
        clearInterval(this.refreshInterval);
        clearTimeout(this.refreshTimeout);
        this.refreshSource.cancel()
    }
    public async beforeDestroy() {
        clearInterval(this.refreshInterval);
        clearTimeout(this.refreshTimeout);
        this.refreshSource.cancel()
    }


}
