import axios from 'axios';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import { GetJobsRequest, GetJobsResponse, GetQueuesReportRequest, GetQueuesReportResponse, IJobDescription, IRunningJob, QueueReport } from '@/utils/api/SchedulerApiClient';
import Apis from '@/utils/api/Apis';
import Vue from 'vue';

@Module({ name: 'queue', stateFactory: true, namespaced: true })
export default class Queue extends VuexModule {
    private _queueCount: {[queue: string]: number } = {}

    public get queueCount() {
        return this._queueCount;
    }

    @Mutation
    public loadQueuesCommit(args: GetQueuesReportResponse) {
        if(!args.queues) return;

        for(let queue of args.queues)
        {
            Vue.set(this._queueCount, queue.detailsKey || '', queue.count);
        }
    }

    @Action({rawError:true})
    public async loadQueues() {
        const queueResponse = await Apis.schedulerApi.getQueuesReport(<GetQueuesReportRequest>{});
        this.loadQueuesCommit(queueResponse);

    }
}
