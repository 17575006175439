














































import StoreMixin from "@/mixins/StoreMixin";
import NorriqHistoryTable from "@/components/history/HistoryTable.vue";
import NorriqPageRenderer from "@/components/layout/PageRenderer.vue";
import { Component, Mixins } from "vue-property-decorator";
import { IDebugCommand, IJobDescription } from "@/utils/api/SchedulerApiClient";
import NorriqJobCard from "@/components/jobs/JobCard.vue";
import Apis from "@/utils/api/Apis";
import { BModal } from "bootstrap-vue";
@Component({
  components: {
    NorriqPageRenderer,
    NorriqJobCard,
    NorriqHistoryTable,
  },
})
export default class Job extends Mixins(StoreMixin) {
  public selectedJobTextResponse: string = "";

  public debugTitle: string = "";
  public debugResult: string = "";

  public get jobId() {
    return this.$route.params.id;
  }

  public get job(): IJobDescription | undefined {
    return this.$jobStore.jobs.find((j) => j.job.id == this.jobId);
  }

  public get searchQuery() {
    return {
      type: "equals",
      field: "configuration.id",
      value: this.jobId,
    };
  }

  public async onStartDebugClick(debugCommand: IDebugCommand) {
    this.debugTitle = debugCommand.description || debugCommand.name || "";
    this.debugResult = "Loading";
    const modal = <BModal>this.$refs.debugCommand;
    modal.show();
    try {
      const debugResult = await Apis.schedulerApi.executeDebugText({ jobId: this.jobId, command: debugCommand.name });
      this.debugResult = debugResult.text || "No result";
    } catch (e) {
      this.debugResult = <string>e;
    }
  }

  public async onSeeConfigurationClick() {
    this.debugTitle = "Configuration";
    this.debugResult = this.job?.job.configuration;
    const modal = <BModal>this.$refs.debugCommand;
    modal.show();
  }

  public async onStartJobClick(mode: string) {
    if (this.jobId) {
      const startJob = await Apis.schedulerApi.startJob({ jobConfigurationId: this.jobId, mode });
      this.$router.push(`/history/${startJob.queuedJobId}`);
    }
  }
  async mounted() {
    await this.$jobStore.loadJobs();
  }
}
